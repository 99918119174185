import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';

import Routes from './Routes';

const meta = {
  title: 'ARIA - Web Portal',
};

class MainApp extends Component { // eslint-disable-line
  render() {
    return (
      <DocumentMeta {...meta} >
        <Routes />
      </DocumentMeta>
    );
  }
}

export default MainApp;
