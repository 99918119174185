import axios from 'app/helpers/MyAxios';
import * as Types from './types';

export default {
  getKey: oneTimeToken =>
    dispatch => axios.get(axios.addApiUrl(`second-auth/get-key?token=${oneTimeToken}`), )
      .then((response) => {
        dispatch({ type: Types.GET_KEY, data: response.data });
        return response.data;
      }),
  validateKey: authKey =>
    dispatch => axios.get(axios.addApiUrl(`second-auth/validate-key?auth_key=${authKey}`), )
      .then((response) => {
        dispatch({ type: Types.VALIDATE_KEY, data: response.data });
        return response.data;
      }),
  clearSecondAuth: () =>
    async dispatch => dispatch({ type: Types.CLEAR_SECOND_AUTH }),
};
