import * as Types from './types';
import * as AccountTypes from 'app/redux/account/types';

const INITIAL_STATE = {
  site: null,  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SELECT_SITE:
      return {
        ...state,
        site: action.site,
      };
    case Types.UNSELECT_SITE:
      return {
        ...state,
        site: null,
      };
    case AccountTypes.LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
