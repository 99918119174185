import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  richtext: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
});

const RichText = ({ classes, className, content, ...props }) =>
  (
    <div
      className={cx(classes.richtext, className)}
      dangerouslySetInnerHTML={{ // eslint-disable-line
        __html: sanitizeHtml(content, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u', 'h1', 'h2', 'h4', 'span', 'br', 'sup', 'sub', 'ins', 'del']),
          allowedAttributes: {
            '*': ['style'],
            ...sanitizeHtml.defaults.allowedAttributes,
          },
          allowedStyles: {
            '*': {
              // Match HEX and RGB
              color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
              'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
              'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
              // Match any number with px, em, or %
              'font-size': [/^\d+(?:px|em|%)$/],
              'font-faimly': [/^.*$/],
            },
          },
          // transformTags: {
          //   '*': function (tagName, attribs) {
          //     console.log(tagName, attribs.style);
          //     return {
          //       tagName: tagName,
          //       attribs: attribs
          //     };
          //   }
          // }
        }),
      }}
      {...props}
    />
  );
RichText.propTypes = {
  content: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
};
RichText.defaultProps = {
  content: '',
  className: null,
};

export default withStyles(styles)(RichText);
