export const DEV = process.env.NODE_ENV === 'development';

export const ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'prod';

const config = {
  dev: {
    //DEV config
    apiHost: 'http://localhost/healthview/aria-portal-api/web/',
    RECAPTCHA_SITE_KEY: '6Lej9VcUAAAAALBG-xgwbeHuK40wlurs_l9nQ6jD',
  },
  uat: {
    //UAT config
    apiHost: 'https://aria-portal-uat.healthviewbio.com/api/',
    RECAPTCHA_SITE_KEY: '6Lej9VcUAAAAALBG-xgwbeHuK40wlurs_l9nQ6jD',
  },
  prod: {
  //Production Config
    testing: false,
    requireSecondAuth: true,
    apiHost: 'https://aria-portal.healthviewbio.com/api/',
  }

}

// console.log('ENV',ENV);
// console.log('process.env.ENV',process.env);

export default {
  DEV,
  basename: DEV ? null : '/',
  testing: true,
  requireSecondAuth: false,
  ...config[ENV],
};
