import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'material-ui-pickers';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Event from '@material-ui/icons/Event';

const MyDatePicker = (props) => {
  const {
    input: { name, value, onChange },
    meta,
    ...rest
  } = props;
  return (
    <DatePicker
      name={name}
      value={value || null}
      onChange={onChange}
      format="YYYY-MMM-DD"
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      keyboardIcon={<Event />}
      clearable
      autoOk
      {...rest}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched ? meta.error : null}
    />
  );
};
MyDatePicker.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

export default MyDatePicker;
