import * as Types from './types';
import * as AccountTypes from 'app/redux/account/types';

const INITIAL_STATE = {
  drawerOpen: false,
  menuOpen: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.OPEN_DRAWER:
      return {
        ...state,
        drawerOpen: true,
      };
    case Types.CLOSE_DRAWER:
      return {
        ...state,
        drawerOpen: false,
      };
    case Types.OPEN_SUBMENU:
      return {
        ...state,
        menuOpen: action.menu,
      };
    case Types.CLOSE_SUBMENU:
      return {
        ...state,
        menuOpen: '',
      };
    case AccountTypes.LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
