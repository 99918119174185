import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField } from 'redux-form-material-ui';
import ReCaptcha from 'app/components/form/ReCaptcha';
import axios from 'app/helpers/MyAxios';
import { RequiredValidator, EmailAddressValidator} from 'app/helpers/Utils';
import { handle422 } from 'app/helpers/ReduxFormHelper';

const NotARobot = (value) => {
  if (!value) {
    return 'Please prove you are not a robot.';
  }
  return undefined;
};

const styles = theme => ({
  middle: {
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 6,
    },
  },
  root: {
    borderRadius: '10px',
    maxWidth: 800,
    width: 'calc(100% - 30px)',
    // padding: theme.spacing.unit,
  },
  header: {
    borderTopLeftRadius: theme.spacing.unit * 2,
    borderTopRightRadius: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBlock: {
    display: 'inline-flex',
  },
  headerLogo: {
    width: 36,
    height: 36,
    marginRight: theme.spacing.unit,
  },
  headerText: {
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.3,
    textTransform: 'uppercase',
  },
  content: {
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing.unit * 5,
      paddingLeft: theme.spacing.unit * 5,
      paddingRight: theme.spacing.unit * 5,
    },
  },
  field: {
    marginBottom: theme.spacing.unit * 2,
  },
  footer: {
    padding: theme.spacing.unit * 2,
  },
  bottonBar: {
    textAlign: 'center',
  },
});

class ForgetPasswordView extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onClose: PropTypes.func.isRequired,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    showSuccess: false,
  }

  async handleSubmit(values) {
    try {
      await axios.post(axios.addApiUrl('account/reset-password'), values);
      this.props.reset();
      this.openDialog();
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  openDialog = () => {
    this.setState({ showSuccess: true });
  }

  closeDialog = () => {
    this.setState({ showSuccess: false });
    this.props.onClose();
  }


  render() {
    const { classes, submitting, handleSubmit } = this.props;
    const { showSuccess } = this.state;
    return (
      <Paper className={classes.root}>
        <Dialog
          onClose={this.closeDialog}
          open={showSuccess}
          maxWidth="md"
        >
          <DialogTitle>Your request for resetting password has been submitted.</DialogTitle>
          <DialogContent>
            <DialogContentText>
            You will receive an email for instructions to reset your password.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">OK</Button>
          </DialogActions>
        </Dialog>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className={classes.header}>
            <div className={classes.headerBlock}>
              <Typography varient="h6" color="inherit" className={classes.headerText}>Request Password Reset</Typography>
            </div>
          </div>
          <div className={classes.content}>
            <Typography gutterBottom>
              Please fill out your email. A link to reset password will be sent there.
            </Typography>
            <Field
              name="email"
              fullWidth
              color="primary"
              placeholder="Email"
              component={TextField}
              validate={[RequiredValidator, EmailAddressValidator]}
              className={classes.field}
            />
            <Field
              // fullWidth
              name="captcha"
              color="primary"
              component={ReCaptcha}
              validate={[NotARobot]}
              // startAdornment={<IconHolder />}
              className={classes.field}
            />
          </div>
          <div className={classes.footer}>
            <div className={classes.bottonBar}>
              <Button type="submit" color="primary" disabled={submitting} size="large">Submit</Button>
            </div>
          </div>
        </form>
      </Paper>
    );
  }
}

export default withStyles(styles)(reduxForm({
  form: 'ForgetPassword',
})(ForgetPasswordView));
