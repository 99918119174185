import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100vh',
  },
  mainFrame: {
    flex: '1 1 auto',
    display: 'flex',
  },
});

const useDefaultLayout = (Component) => {
  const DefaultLayout = ({ classes, ...props }) => (
    <div className={classes.appFrame}>
      <CssBaseline />
      <main className={classes.mainFrame} >
        <Component {...props} />
      </main>
    </div >
  );

  DefaultLayout.propTypes = {
    classes: PropTypes.shape({}).isRequired,
  };

  return withStyles(styles)(DefaultLayout);
};

export default useDefaultLayout;
