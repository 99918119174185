import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

import { handle422 } from 'app/helpers/ReduxFormHelper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import RichText from 'app/components/RichText';

const styles = theme => ({
    textField: {
        marginTop: theme.spacing.unit * 2,
    },
    submitButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        width: '90px',
    },
    popupContentContainer: {
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      marginTop: theme.spacing.unit * 3,
    },
    link: {
      color: theme.palette.primary.main,
    },
    root: {
      alignItems: 'start',
    },
    label: {
      fontWeight: 'bold',
      lineHeight: '1.2',
      alignSelf: 'center',
    }
});

class UserManualFormView extends Component {

  constructor(props) {
    super(props);
    this.state ={
      confirmCheck: false,
    };
  }
  async handleUserManualSubmit(id){
    try {
      await this.props.create({
        id: this.props.taskContent.id,
        checked: this.state.confirmCheck?1:0,
      });
      this.props.onClose();
      this.props.taskContent.sucess_msg ?
      alert(this.props.taskContent.sucess_msg)
      :
      alert('');
      this.props.logout();
      //this.props.dotask();
    } catch (e) {
        handle422(e.response);
    }
  }

  onCreate = async (model) => {
    try {
      const newPatient = await this.props.create(model);
      return newPatient;
    } catch (e) {
      handle422(e.response);
    }
  }

  render() {
    const {classes, handleSubmit, taskContent} = this.props;
    const {confirmCheck} = this.state;
    const onSubmit = handleSubmit(v => this.handleUserManualSubmit(v));
    return (
      <div className={classes.popupContentContainer}>
        <RichText content={taskContent.detail} />
        <ul><li><Typography>
          <a className={classes.link} href="#" onClick = {()=>this.props.usermanual()}>
            User Manual for ARIA
          </a>
        </Typography></li></ul>
        <form onSubmit={onSubmit}>
          <FormControlLabel
            control={<Checkbox checked={confirmCheck} onChange={event => this.setState({confirmCheck:event.target.checked})} />}
            label={taskContent.concerns}
            classes={{label:classes.label}}
            className={classes.root}
          />
          <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={confirmCheck?false: true}
            className={classes.submitButton}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={this.props.onClose}
            className={classes.submitButton}
          >
            Cancel
          </Button>
          </DialogActions>
        </form>
      </div>
    )
  }
}
const UserManualForm = reduxForm({
  form: 'UserManualForm',
});

export default withStyles(styles)(UserManualForm(UserManualFormView));
