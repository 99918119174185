import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { handle422 } from 'app/helpers/ReduxFormHelper';
import n2br from 'app/helpers/n2br';
import DropzoneImageUpload from 'app/components/form/DropzoneImageUpload';
import DatePicker from 'app/components/form/DatePicker';
import Spacer from 'app/components/Spacer';
import axios from 'app/helpers/MyAxios';

const styles = theme => ({
  root:{
    padding: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: 20,
  },
  imageUpload:{
  },
  twoColumn:{
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'no-wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  twoColumnLeft:{
    [theme.breakpoints.up('md')]: {
      marginRight: 10,
    },
  },
  twoColumnRight:{
    [theme.breakpoints.up('md')]: {
      marginLeft: 10,
    },
  },
  selectedChip:{
    marginRight: 10,
  }
});

class RecordCreate extends Component {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    const { create, setNotiMessage, history } = this.props;
    try {
      const formData = new FormData(); // eslint-disable-line
      Object.keys(values).forEach(key => formData.append(key, values[key]));
      if ( typeof values.l_image !== 'undefined' )
        formData.set('l_image',values.l_image[0]);
      if ( typeof values.r_image !== 'undefined' )
        formData.set('r_image',values.r_image[0]);
      const data = await create(formData);
      data.analysis.map(ana => {
        return axios.get(axios.addApiUrl(`cloud-analyser/refresh?analysis_id=${ana.id}`))
      })
      setNotiMessage ("New record created, reference number: " + data.ref_no + '.' );
      history.replace(`/records`);
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  render() {
    const {classes, selectedSite, history} = this.props;
    const onSubmit = this.props.handleSubmit(values => this.onSubmit(values));
    return (
      <form onSubmit={onSubmit}>
        <Card className={classes.root}>
          <Toolbar>
            <Typography variant="h5">New Analysis</Typography>
            <Spacer />
            <Button variant="contained" onClick={()=>{history.push('/records')}}>Back to Analysis List</Button>
          </Toolbar>
          <CardContent>
            <div className={classes.twoColumn}>
              <Field
                component={TextField}
                className={cx(classes.twoColumnLeft, classes.textField)}
                name="site_id"
                label="Site"
                variant="outlined"
                readOnly
                fullWidth
                select
              >
                <MenuItem key={selectedSite.id} value={selectedSite.id}>
                  {selectedSite.name}
                </MenuItem>
              </Field>
              <Field
                component={TextField}
                className={cx(classes.twoColumnRight, classes.textField)}
                name="camera_id"
                label="Camera"
                variant="outlined"
                fullWidth
                select
              >
                {selectedSite.cameras.map(camera => (
                  <MenuItem key={camera.id} value={camera.id}>
                    {camera.brand + ' ' + camera.model + ' (S/N: ' + camera.serial + ')'}
                  </MenuItem>
                ))}
              </Field>
            </div>
            <Field
              component={TextField}
              className={classes.textField}
              name="analysis_type_codes"
              label="Analysis Type"
              variant="outlined"
              fullWidth
              select
              SelectProps = {{
                multiple: true,
                renderValue: selectedItems => (
                    <div>
                      {selectedItems ?
                          selectedItems.map(value => (
                          <Chip
                            key={value}
                            label={ (this.props.selectedSite.analysisTypes.find(type => {
                              return type.code === value
                            }) || {}).name}
                            onDelete={ event => {
                              event.stopPropagation();
                              event.target.value = selectedItems.filter(item => {
                                return item !== value;
                              });
                              this.props.change('analysis_type_codes',event.target.value);
                            }}
                            className={classes.selectedChip}
                          />
                        ))
                        : ''
                      }
                    </div>
                  )
              }}
            >
              {selectedSite.analysisTypes.map(analysisType => (
                      <MenuItem key={analysisType.code} value={analysisType.code}>
                        <Checkbox color="primary" checked={this.props.selectedAnalysisTypeCodes ? this.props.selectedAnalysisTypeCodes.includes(analysisType.code) : false} />
                        {analysisType.name}
                      </MenuItem>
              ))}
            </Field>
            <div className={classes.twoColumn}>
              <Field
                component={TextField}
                className={cx(classes.twoColumnLeft, classes.textField)}
                name="customer_ref_no"
                label="Customer Reference Number"
                variant="outlined"
                fullWidth
              >
              </Field>
              <Field
                component={TextField}
                className={cx(classes.twoColumnRight, classes.textField)}
                name="client_initial"
                label="Client's Initial"
                variant="outlined"
                fullWidth
              >
              </Field>
            </div>
            <div className={classes.twoColumn}>
              <Field
                component={DatePicker}
                className={cx(classes.twoColumnLeft, classes.textField)}
                name="dob"
                label="Date of Birth"
                variant="outlined"
                fullWidth
                />
              <Field
                component={TextField}
                className={cx(classes.twoColumnRight, classes.textField)}
                name="sex"
                label="Gender"
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem key="" value=""></MenuItem>
                <MenuItem key="M" value="M">Male</MenuItem>
                <MenuItem key="F" value="F">Female</MenuItem>
              </Field>
            </div>
            <div className={classes.twoColumn}>
              <Field
                component={DropzoneImageUpload}
                className={cx(classes.twoColumnLeft, classes.imageUpload)}
                name="l_image"
                noFileMessage={n2br("Retinal Image of Left Eye \r\n [Click to browse or drop image here]")}
              />
              <Field
                component={DropzoneImageUpload}
                className={cx(classes.twoColumnRight, classes.imageUpload)}
                name="r_image"
                type='file'
                noFileMessage={n2br("Retinal Image of Right Eye \r\n [Click to browse or drop image here]")}
              />
            </div>
          </CardContent>
          <CardContent>
            <Button variant="contained" color="primary" type="submit" disabled={this.props.submitting}>Create</Button>
            <Button color="primary" onClick={this.props.reset} disabled={this.props.pristine}>Clear</Button>
          </CardContent>
        </Card>
      </form>);
  }
}

const RecordCreateForm = reduxForm({
  form: 'RecordCreateForm',
  enableReinitialize: true,
})(withStyles(styles)(RecordCreate));

const selector = formValueSelector('RecordCreateForm');

export default withRouter(connect(state => ({
  selectedAnalysisTypeCodes: selector (state, 'analysis_type_codes'),
}))(RecordCreateForm));
