import * as Types from './types';

export default {
  openDrawer: menu =>
    async dispatch => dispatch({ type: Types.OPEN_DRAWER }),
  closeDrawer: () =>
    async dispatch => dispatch({ type: Types.CLOSE_DRAWER }),
  openMenu: menu =>
    async dispatch => dispatch({ type: Types.OPEN_SUBMENU, menu }),
  closeMenu: () =>
    async dispatch => dispatch({ type: Types.CLOSE_SUBMENU }),
};
