import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class ProtectedRoute extends Component { // eslint-disable-line
  static propTypes = {
    user: PropTypes.shape({ id: PropTypes.any }),
    redirectTo: PropTypes.string,
    reverseCheck: PropTypes.bool,
  };

  static defaultProps = {
    redirectTo: '/',
    user: null,
    selectedSite:null,
    reverseCheck: false,
    needSelectedSite: false,
    isAccountManager: false,
    needReadUserManual: true,
  }

  checkIsAccountManager  = user => user!==null && user.roles.filter(role => role.name==="AccountManager").length > 0;
  checkReadUserManual = user => (user.tasks!==null && user.tasks!==undefined) &&  user.tasks.filter(task => task.type_code==="userguide").length > 0;

  render() {
    const {
      user,
      selectedSite,
      needSelectedSite,
      redirectTo,
      reverseCheck,
      needAccountManager,
      needReadUserManual,
      ...props
    } = this.props;
    const haveLogin = (user !== null && user.id !== null);
    // console.log('check the account have read user manual or not',this.checkReadUserManual(user));
    // console.log('check the account have accountManager or not',this.checkIsAccountManager(user));
    if (Boolean(haveLogin) !== Boolean(reverseCheck)) { // haveLogin XOR reverseCheck
        if (Boolean(needReadUserManual)){ //check read manual
          if(this.checkReadUserManual(user)){
            if (Boolean(needAccountManager)){ //account manager role
              if(this.checkIsAccountManager(user)){
                return <Route {...props} />;
              } else{
                alert("You are not permitted to access!")
                return <Redirect to={'/me'} />
              }
            }
            if (Boolean(!needSelectedSite) ||  selectedSite){ //selected site
              return <Route {...props} />;
            } else {
              alert("You need to select a site first!")
              return <Redirect to={'/me'} />
            }
            return <Route {...props} />;
          } else{
            alert("Please go through the entire ARIA Operation Manual first.")
            return <Redirect to={'/dashboard'} />
          }
        } else{
          return <Route {...props} />;
        }
    }
    return <Redirect to={redirectTo} />;
  }
}


const mapStateToProps = ({ account, selected }) => ({
  user: account.user,
  selectedSite: selected.site,
});

export default connect(mapStateToProps)(ProtectedRoute);
