import AccountMe from './Account/AccountMeContainer';
import DashboardMain from './Dashboard/DashboardMainContainer';
import LoginView from './Login/LoginViewContainer';
import RecordCreate from './Record/RecordCreateContainer';
import UsageReportList from './UsageReport/UsageReportListContainer';
import RecordList from './Record/RecordListContainer';
export default {
  AccountMe,
  DashboardMain,
  LoginView,
  UsageReportList,
  RecordList,
  RecordCreate,
};
