import _ from 'lodash';
import * as actions from './types';
import service from './service';

const fetchList = (search, dispatch) =>
  service
    .fetchList(search)
    .then(response => dispatch({ type: actions.FETCH_LIST, data: response.data }));

const getNewSearch = (search, getState) => {
  const { form, sort, page, pageSize } = getState().record;
  return _.merge({}, { form, sort, page, pageSize }, search);
};

const creators = {
  sortChange: sort =>
    async (dispatch, getState) => {
      dispatch({ type: actions.SORT_CHANGE, sort });
      return fetchList(getNewSearch({ sort }, getState), dispatch);
    },
  pageChange: page =>
    async (dispatch, getState) => {
      dispatch({ type: actions.PAGE_CHANGE, page });
      return fetchList(getNewSearch({ page }, getState), dispatch);
    },
  searchFormSubmit: form =>
    async (dispatch, getState) => {
      dispatch({ type: actions.SEARCH_FORM_SUBMIT, form });
      return fetchList(getNewSearch({ form, page: 0 }, getState), dispatch);
    },

  create: data =>
    async (dispatch, getState) => {
      const response = await service.create(data);
      fetchList(getNewSearch({}, getState), dispatch);
      return response.data;
    },

  fetchOne: id =>
    async (dispatch) => {
      const response = await service.fetchOne(id);
      dispatch({ type: actions.FETCH_ONE, model: response.data });
      return response.data;
    },

  clearOne: () =>
    async dispatch => dispatch({ type: actions.CLEAR_ONE }),

  update: form =>
    async (dispatch, getState) => {
      const response = await service.update(form);
      dispatch({ type: actions.FETCH_ONE, model: response.data });
      fetchList(getNewSearch({}, getState), dispatch); // refresh the list
      return response.data;
    },

  delete: model =>
    async (dispatch, getState) => {
      const response = await service.deleteOne(model.id);
      fetchList(getNewSearch({}, getState), dispatch);
      return response.data;
    },
};

export default creators;
