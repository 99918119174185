import MyQuery from 'app/helpers/MyQuery';
import axios from 'app/helpers/MyAxios';

const service = {
  fetchList: (search) => {
    const qs = MyQuery(search);
    return axios.get(axios.addApiUrl(`records?${qs}`));
  },
  fetchOne: id => axios.get(axios.addApiUrl(`records/${id}`)),
  create: data => axios.post(axios.addApiUrl('records'), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  update: data => axios.put(axios.addApiUrl(`records/${data.id}`), data),
  deleteOne: id => axios.delete(axios.addApiUrl(`records/${id}`)),
};

export default service;
