import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const EMPTY_FORM = {
  token: '',
  submitted: false,
};

const styles = theme => ({
    textField: {
        marginTop: theme.spacing.unit * 2,
    },
    submitButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
    },
});

class GetSecondAuthKeyForm extends Component {

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      ...EMPTY_FORM,
    };
  }

  submitForm = e => {
    if(e.preventDefault){
      e.preventDefault();
    }
    this.props.getKey(this.state.token);
    this.setState({submitted: true});
  }

  render() {
    const { classes, secondAuthKey} = this.props;
    return (
      <div className="getSecondAuthKeyForm">
        <form onSubmit={this.submitForm}>
            <TextField
                name="token"
                label="One-time Token"
                fullWidth
                required
                error={ secondAuthKey=="" && this.state.submitted ? true: false }
                onChange={event => this.setState({ token: event.target.value })}
                className={classes.textField}
                helperText={ secondAuthKey=="" && this.state.submitted ? 'Invalid Token': '' }
            />
            <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classes.submitButton}
            >
              Submit
            </Button>
        </form>
      </div>
    );
  }
}
export default withStyles(styles)(GetSecondAuthKeyForm);
