import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DocumentMeta from 'react-document-meta';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import ForgetPasswordForm from './ForgetPasswordForm';

const styles = theme => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logoBar: {
    padding: '8px 0px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  logoBarClose: {
    color: theme.palette.primary.contrastText,
  },
  middle: {
    flex: 1,
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 6,
    },
  },
});

const ForgetPasswordDialog = ({ classes, onClose, ...props }) => {
  return (
    <Dialog
      onClose={onClose}
      {...props}
    >
      <DocumentMeta title="ARIA - Web Portal Forgot Password">
        <div className={classes.main}>
          <div className={classes.logoBar}>
            <Tooltip title="Close"><IconButton className={classes.logoBarClose} onClick={onClose}><Close color="inherit" /></IconButton></Tooltip>
          </div>
          <div className={classes.middle}>
            <ForgetPasswordForm onClose={onClose} />
          </div>
          <div className={classes.logoBar} >
            <div>&nbsp;</div>
          </div>
        </div>
      </DocumentMeta>
    </Dialog>
  );
};
ForgetPasswordDialog.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(ForgetPasswordDialog);
