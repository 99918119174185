import axios from 'app/helpers/MyAxios';
import * as Types from './types';

export default {
  selectSite: id =>
    dispatch => axios.get(axios.addApiUrl(`sites/${id}`), id)
      .then((response) => {
        dispatch({ type: Types.SELECT_SITE, site: response.data });
        return response.data;
      }),
  unselectSite: () =>
    async dispatch => dispatch({ type: Types.UNSELECT_SITE }),
};
