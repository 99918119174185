import React, { Component } from 'react';
import { TextField } from 'redux-form-material-ui';
import { withStyles } from '@material-ui/core/styles';

import SearchFormBase from 'app/components/SearchFormBase';

const styles = theme => ({
  field: {
    marginRight: theme.spacing.unit * 2,
    minWidth: 150,
  },
});

const EMPTY_FORM = {
  ref_no: '',
  customer_ref_no: '',
};

class RecordSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref_no: props.searchRefNo ? props.searchRefNo: '',
      customer_ref_no: props.searchCustomerRefNo ? props.searchCustomerRefNo: '',
      site_id: props.selectedSiteId ? props.selectedSiteId: '',
    };
    this.submitForm = this.submitForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      ref_no: nextProps.searchRefNo ? nextProps.searchRefNo: '',
      customer_ref_no: nextProps.searchCustomerRefNo ? nextProps.searchCustomerRefNo: '',
      site_id: nextProps.selectedSiteId ? nextProps.selectedSiteId: '',
    });
  }
  submitForm(event = {}) {
    if(event.preventDefault){
      event.preventDefault();
    }
    console.log(this.state);
    this.props.searchFormSubmit(this.state);
    return false;
  }
  clearForm(event = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.setState({
      ...EMPTY_FORM,
    }, () => this.submitForm());
  }
  render() {
    const {classes} = this.props;
    return (
      <SearchFormBase onSubmit={this.submitForm} onClear={this.clearForm}>
        <TextField
          className={classes.field}
          name="ref_no"
          label="ARIA Ref No"
          value={this.state.ref_no}
          onChange={event => this.setState({ ref_no: event.target.value })}
        />
        <TextField
          className={classes.field}
          name="customer_ref_no"
          label="Customer Ref No"
          value={this.state.customer_ref_no}
          onChange={event => this.setState({ customer_ref_no: event.target.value })}
        />
      </SearchFormBase>
    );
  }
}
export default withStyles(styles)(RecordSearchForm);
