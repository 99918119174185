import * as Types from './types';

const INITIAL_STATE = {
  secondAuthKey: null,
  isValidKey: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_KEY:
      return {
        ...state,
        secondAuthKey: action.data.secondAuthKey
      };
    case Types.VALIDATE_KEY:
      return {
        ...state,
        isValidKey: action.data.isValidKey
      };
    case Types.CLEAR_SECOND_AUTH:
      return INITIAL_STATE;
    default:
      return state;
  }
};
