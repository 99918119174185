import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3 ,
  },
  formContainer: {
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
  },
  buttonContainer: {
    flex: 0.5,
    minWidth: 150,
    alignSelf: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing.unit,
  }
});

class SearchFormBase extends Component {
  static defaultProps = {
    onSubmit: () => {},
    onClear: () => {},
    children: null,
  };
  render(){
    const { classes, children, onSubmit, onClear} = this.props;
    return (
      <div>
        <div className={classes.root}>
          <form onSubmit={onSubmit}>
            <div className={classes.formContainer}>
              {children}
              <div className={classes.buttonContainer}>
                <Button className={classes.button} variant="contained" type="submit" color="primary">SEARCH</Button>
                <Button className={classes.button} variant="contained" onClick={onClear}>CLEAR</Button>
              </div>
            </div>
          </form>
        </div>
        <Divider/>
      </div >
    );
  }
}
export default withStyles(styles)(SearchFormBase);
