import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from 'app/components/ProtectedRoute';
import layouts from './layouts';
import containers from './containers';
const NotFound = () => <div><h1>Page Not Found.</h1></div>;

const Routes = () => (
  <Switch>
    <ProtectedRoute exact path="/" component={layouts.useDefaultLayout(containers.LoginView)} reverseCheck redirectTo="/dashboard" needReadUserManual={false}/>
    <ProtectedRoute exact path="/dashboard" component={layouts.useLogedInLayout(containers.DashboardMain)} needReadUserManual={false}/>

    <ProtectedRoute exact path="/records" component={layouts.useLogedInLayout(containers.RecordList)} needSelectedSite />
    <ProtectedRoute exact path="/records/create" component={layouts.useLogedInLayout(containers.RecordCreate)} needSelectedSite />

    <ProtectedRoute exact path="/usages" component={layouts.useLogedInLayout(containers.UsageReportList)} needAccountManager />

    <ProtectedRoute exact path="/me" component={layouts.useLogedInLayout(containers.AccountMe)} needReadUserManual={false}/>
    <Route exact path="/404" component={layouts.useDefaultLayout(NotFound)} />
    <Redirect path="/*" to="/404" />
  </Switch>
);

export default Routes;
