import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';

const NotificationSnackbar = ({
  onClose,
  ...props
}) =>
  (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={5000}
      onClose={onClose}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <IconClose />
        </IconButton>,
      ]}
      {...props}
    />
  );
NotificationSnackbar.propTypes = {
  onClose: PropTypes.func,
};

NotificationSnackbar.defaultProps = {
  onClose: null,
};

export default NotificationSnackbar;
