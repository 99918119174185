import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CodeActions from 'app/redux/code/actions';
import NotificationSnackbarActions from 'app/redux/notificationSnackbar/actions';
import RecordActions from 'app/redux/record/actions';
import RecordList from './RecordList';

const mapStateToProps = ({ record, selected, code }) => ({
  ...record,
  selectedSite: selected.site,
  analysisTypes: code.analysisTypes,
});

const mapDispatchToProps = {
  fetchAnalysisTypeCode: CodeActions.fetchAnalysisTypeCode,
  searchFormSubmit: RecordActions.searchFormSubmit,
  pageChange: RecordActions.pageChange,
  sortChange: RecordActions.sortChange,
  setNotiMessage: NotificationSnackbarActions.setNotiMessage,
  
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordList));
