import * as Actions from './types';
import * as AccountTypes from 'app/redux/account/types';

const INITIAL_STATE = {
  form: {},
  sort: [
    {
      key: 'id',
      asc: false,
    },
  ],
  page: 0,
  totalElements: 0,
  totalPages: 0,
  pageSize: 20,
  numberOfElements: 0,
  data: [],
  init: false,
  submitting: false,
  done: false,
};

const record = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.FETCH_LIST:
      return {
        ...state,
        init: true,
        data: action.data.items,
        page: (action.data.meta.currentPage - 1),
        totalElements: action.data.meta.totalCount,
        totalPages: action.data.meta.pageCount,
        pageSize: action.data.meta.perPage,
        numberOfElements: action.data.items.length,
        submitting: false,
        done: true,
      };
    case Actions.SORT_CHANGE:
      return {
        ...state,
        submitting: true,
        done: false,
        sort: action.sort,
      };
    case Actions.SEARCH_FORM_SUBMIT:
      return {
        ...state,
        submitting: true,
        done: false,
        form: {
          ...INITIAL_STATE.form,
          ...action.form,
        },
      };
    case Actions.PAGE_CHANGE:
      return {
        ...state,
        submitting: true,
        done: false,
        page: action.page,
      };
    case AccountTypes.LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default record;
