import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import accountActions from 'app/redux/account/actions';
import axios from 'app/helpers/MyAxios';
import { handle422 } from 'app/helpers/ReduxFormHelper';


const EMPTY_FORM = {
  password: '',
  new_password: '',
  confirm_password: '',
};

const styles = theme => ({
    textField: {
        marginTop: theme.spacing.unit * 2,
    },
    submitButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
    },
});

class ChangePasswordView extends Component {

  constructor(props) {
    super(props); 
    this.state = {
      ...EMPTY_FORM,
    };
  }

  async handleCPSubmit(values){
    try {
      await axios.post(axios.addApiUrl('account/change-password'), values);
      alert("Password changed. Please login again with the new password.");
      this.props.logout();
    } catch (e) {
      handle422(e.response);
    }
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const onSubmit = handleSubmit(v => this.handleCPSubmit(v));
    return (
      <div className="getSecondAuthKeyForm">
        <form onSubmit={onSubmit}>
            <Field
              name="password"
              label="Original Password"
              fullWidth
              type="password"
              component={TextField}
              className={classes.textField}
            />
            <Field
              name="new_password"
              label="New Password" 
              fullWidth
              type="password"
              component={TextField}
              className={classes.textField}
            />
            <Field
              name="confirm_password"
              label="Confirm Password"     
              fullWidth
              type="password"
              component={TextField}
              className={classes.textField}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.submitButton}
            >
              Submit
            </Button>
        </form>
      </div>
    );
  }
}

const ChangePasswordForm = reduxForm({
  form: 'ChangePasswordForm',
  enableReinitialize: true,
  initialValues: EMPTY_FORM,
});

const mapStateToProps = null;
const mapDispatchToProps = {
  ...accountActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(ChangePasswordForm(ChangePasswordView)),
);
