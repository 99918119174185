import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import Spacer from 'app/components/Spacer';
import NotificationSnackbar from 'app/components/NotificationSnackbar';
import LeftDrawerActions from 'app/redux/leftDrawer/actions';
import NotificationSnackbarActions from 'app/redux/notificationSnackbar/actions';
import Config from 'app/constants/Config';

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: 5,
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

class TopAppBar extends Component {// eslint-disable-line
  static defaultProps = {
  }
  render() {
    const { classes, openDrawer, selectedSite, loginUser } = this.props;
    return (
      <AppBar
        position="fixed"
        className={cx(classes.appBar)}
      >
        <NotificationSnackbar
          open={Boolean(this.props.notificationSnackbar.notiMessage)}
          message={this.props.notificationSnackbar.notiMessage}
          onClose={() => this.props.setNotiMessage('')}
        />
        <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={openDrawer}
              className={cx(classes.menuButton )}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
            ARIA Web Portal {Config.testing ? "(Testing)":""}
            </Typography>
            <Spacer />
            <Typography color="inherit" className = {classes.hideOnMobile}>
            Current site: {loginUser.partner.name} - {selectedSite ? selectedSite.name: 'Not selected'}
            </Typography>
            <IconButton
              color="inherit"
              aria-label="Site settings"
              component={Link} 
              to={`/me`}
            >
             <SettingsApplicationsIcon />
            </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
}
const mapStateToProps = ({ leftDrawer, notificationSnackbar, selected, account }) => ({
  leftDrawer: leftDrawer,
  notificationSnackbar: notificationSnackbar,
  selectedSite: selected.site,
  loginUser: account.user,
})

const mapDispatchToProps = {
  ...LeftDrawerActions,
  ...NotificationSnackbarActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(TopAppBar),
);
