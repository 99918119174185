import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UsageReportList from './UsageReportList';
// import UsageRepotActions from 'app/redux/usageReport/actions';

const mapStateToProps = () => ({
  //...usageReport,
});

const mapDispatchToProps = {
  // searchFormSubmit: UsageRepotActions.searchFormSubmit,
  // pageChange: UsageRepotActions.pageChange,
  // sortChange: UsageRepotActions.sortChange,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsageReportList));
