import { connect } from 'react-redux';
import AccountActions from 'app/redux/account/actions';
import SecondAuthActions from 'app/redux/secondAuth/actions';
import SelectedActions from 'app/redux/selected/actions';

import LoginView from './LoginView';

const mapStateToProps = ({ secondAuth }) => ({
  secondAuthKey: secondAuth.secondAuthKey,
  isValidKey: secondAuth.isValidKey,  
});

const mapDispatchToProps = {
  login: AccountActions.login,
  getKey: SecondAuthActions.getKey,
  clearSecondAuth: SecondAuthActions.clearSecondAuth,
  validateKey: SecondAuthActions.validateKey,
  selectSite: SelectedActions.selectSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
