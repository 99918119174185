import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Dashboard from '@material-ui/icons/Dashboard';
import ListAlt from '@material-ui/icons/ListAlt';
import BarChart from '@material-ui/icons/BarChart';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Person from '@material-ui/icons/Person';

import accountActions from 'app/redux/account/actions';
import LeftDrawerActions from 'app/redux/leftDrawer/actions';

const drawerWidth = 320;
const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    fontFamily: theme.typography.fontFamily,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  collapseMenu: {
    listStyle: 'none',
  },
});

const isAccountManager = user => user !==null && user.roles.filter(role => role.name==="AccountManager").length > 0;

const checkReadUserManual = tasks => (tasks!==null && tasks!==undefined) && tasks.filter(task => task.type_code==="userguide").length > 0;


class LeftDrawer extends Component {// eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired, // eslint-disable-line
    menuOpen: PropTypes.string,
    openMenu: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
  };
  static defaultProps = {
    menuOpen: '',
  }

  render() {
    const { classes, user } = this.props;
    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open = {this.props.drawerOpen}
      >
        <Paper className={classes.drawerPaper}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.props.closeDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
          <ListItem onClick={this.props.closeDrawer} component={NavLink} activeClassName={classes.selected} exact to="/dashboard">
            <ListItemIcon>
              <Dashboard  />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          { checkReadUserManual(user.tasks)?
          <ListItem onClick={this.props.closeDrawer} component={NavLink} activeClassName={classes.selected} exact to="/records">
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary="Analysis" />
          </ListItem>
          :
          null
          }
          { isAccountManager(user) && checkReadUserManual(user.tasks) ?
          <ListItem onClick={this.props.closeDrawer} component={NavLink} activeClassName={classes.selected} exact to="/usages">
            <ListItemIcon>
              <BarChart />
            </ListItemIcon>
            <ListItemText primary="Usage Report" />
          </ListItem>
          :
          null
          }
          <ListItem onClick={this.props.closeDrawer} component={NavLink} activeClassName={classes.selected} exact to="/me">
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="My Account" />
          </ListItem>
          <ListItem button onClick={this.props.logout}>
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Paper>
      </Drawer >
    );
  }
}

const mapStateToProps = ({ leftDrawer, account }) => ({
  ...leftDrawer,
  user: account.user,
});

const mapDispatchToProps = {
  ...accountActions,
  ...LeftDrawerActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(LeftDrawer),
);
