import { connect } from 'react-redux';

import actions from 'app/redux/task/actions';
import AccountAction from 'app/redux/account/actions';
import ManualActions from 'app/redux/manual/actions';
import UserManualForm from './UserManualForm';

const mapStateToProps = null;
const mapDispatchToProps = {
  create: actions.create,
  logout: AccountAction.logout,
  dotask: AccountAction.dotask,
  fetchManual: ManualActions.fetchManual,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(UserManualForm);
