import * as Types from './types';
import * as AccountTypes from 'app/redux/account/types';

const INITIAL_STATE = {
  analysisTypes: [],  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_ANALYSIS_TYPE_CODE:
      return {
        ...state,
        analysisTypes: action.analysisTypes,
      };
    case AccountTypes.LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
