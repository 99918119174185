import * as actions from './types';
import service from './service';

const creators = {
  fetchOne: type =>
    async (dispatch) => {
      const response = await service.fetchOne(type);
      dispatch({ type: actions.FETCH_ONE, data: response.data });
      return response.data;
    },
  create: (values) =>
    async (dispatch) => {
      const response = await service.create(values);
      dispatch({ type: actions.TASKUSER_CREATE, data: response.data });
      return response.data;
    },
};

export default creators;
