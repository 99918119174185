// Copy from Yii's email validator
const EmailRegex = /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

const EmailAddressValidator = (v) => {
  if (v && !EmailRegex.test(v)) {
    return 'Invalid Email Address.';
  }
  return undefined;
};

const RequiredValidator = (value) => {
  if (!value) {
    return 'Required.';
  }
  if (Array.isArray(value) && value.length === 0) {
    return 'Required.';
  }
  return undefined;
};


const parseCode = v => (v && v.value ? v.value : null);
const parseCodes = v => (Array.isArray(v) ? v.map(parseCode) : []);
const toArray = v => (Array.isArray(v) ? v : []);

export {
  EmailAddressValidator,
  RequiredValidator,
  parseCode,
  parseCodes,
  toArray,
};
