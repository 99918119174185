import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTablePaginationActions from './EnhancedTablePaginationActions';

class EnhancedTable extends Component {
  static propTypes = {
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalElements: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    columnData: PropTypes.arrayOf(PropTypes.shape({})),
    numSelected: PropTypes.number,
    noCheckbox: PropTypes.bool,
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        asc: PropTypes.bool,
      })
    ),
    onSelectAllClick: PropTypes.func,
    onChangePage: PropTypes.func.isRequired,
    onChangeSort: PropTypes.func.isRequired,
    renderRow: PropTypes.func.isRequired,
  }
  static defaultProps = {
    page: 0,
    pageSize: 20,
    totalElements: 0,
    sort: [{
      key: 'id',
      asc: false,
    }],
    data: [],
    columnData: [],
    numSelected: 0,
    noCheckbox: false,
    onSelectAllClick: null,
  }

  render() {
    const {
      columnData,
      numSelected,
      sort,
      data,
      onChangeSort,
      onChangePage,
      onSelectAllClick,
      totalElements,
      pageSize,
      page,
      noCheckbox,
      renderRow,
    } = this.props;
    return (
      <Table>
        <EnhancedTableHead
          columnData={columnData}
          numSelected={numSelected}
          order={sort[0].asc ? 'asc' : 'desc'}
          orderBy={sort[0].key}
          onSelectAllClick={onSelectAllClick}
          onRequestSort={onChangeSort}
          rowCount={data.length}
          noCheckbox={noCheckbox}
        />
        <TableBody>
          {data.length === 0 ?
            <TableRow>
              <TableCell colSpan={columnData.length + 1}>
                No Records.
              </TableCell>
            </TableRow>
            : data.map(renderRow)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={columnData.length + 1}
              count={totalElements}
              rowsPerPage={pageSize}
              page={page}
              rowsPerPageOptions={[]}
              onChangePage={onChangePage}
              ActionsComponent={EnhancedTablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
}

export default EnhancedTable;
