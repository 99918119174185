import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { reduxForm, Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { withStyles } from '@material-ui/core/styles';
import HVBLogo from 'app/resources/hvb_logo.png';
import { handle422 } from 'app/helpers/ReduxFormHelper';
import ForgetPasswordDialog from './ForgetPasswordDialog';
import GetSecondAuthKeyForm from './GetSecondAuthKeyForm';
import Config from 'app/constants/Config';


const RequiredValidator = (value) => {
  if (!value) {
    return 'Required.';
  }
  return undefined;
};

const styles = theme => ({
  gutterBottom: {
    marginBottom: theme.spacing.unit,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  logoWrapper: {
    width: 260,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#dee2e6',
  },
  loginBox: {
    width: 260,
    flex: 1,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#dee2e6',
  },
  loginButton: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: '100%',
  },
  passwordResetLink: {
    fontSize: 14,
    textDecoration: 'none',
  },
  secondAuthBox:{
    width: 260,
    flex: 1,
    marginTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#dee2e6',
  },
  validateKeyButton: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: '100%',
  }
});

const meta = {
  title: 'ARIA - Web Portal Login',
};

class LoginView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showForgetPasswordDialog: false,
      showResetAuthKeyDialog: false,
    };
  }

  componentDidMount() {
    if (Config.requireSecondAuth){
      this.props.validateKey(this.props.secondAuthKey);
    }
  }

  async handleLoginSubmit(values) {
    try {
      const response = await this.props.login(values);
      if (typeof response.sites !== 'undefined'){
        const defaultSite = response.sites.find(site => { return site.id === response.default_site_id});
        if (typeof defaultSite !== 'undefined')
           await this.props.selectSite(defaultSite.id);
      }
    } catch (e) {
      handle422(e.response);
      alert("Server connection failed.");
    }
  }

  render() {
    const { handleSubmit, submitting, classes, secondAuthKey, isValidKey } = this.props;
    const onSubmit = handleSubmit(v => this.handleLoginSubmit(v));
    return (
      <DocumentMeta {...meta} extend>
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item xs={12}>
            <ForgetPasswordDialog
              open={this.state.showForgetPasswordDialog}
              onClose={()=>{this.setState({showForgetPasswordDialog:false})}}
              fullScreen
              disableEscapeKeyDown
              disableBackdropClick
              transitionDuration={300}
            />
            <div className={classes.logoWrapper}>
              <img src={HVBLogo} width="200px" alt="ARIA - Portal" className={classes.logo} />
              <Typography variant="h5">Automatic Retinal Image Analyzer</Typography>
              <p></p>
              <Typography variant="h6" color="primary">WEB PORTAL</Typography>
            </div>
            {!Config.requireSecondAuth || secondAuthKey ? (
              !Config.requireSecondAuth || isValidKey ?
              <div className={classes.loginBox}>
                <form onSubmit={onSubmit}>
                  <Field
                    name="email"
                    fullWidth
                    required
                    label="Email"
                    component={TextField}
                    validate={[RequiredValidator]}
                    className={classes.gutterBottom}
                  />
                  <Field
                    name="password"
                    fullWidth
                    required
                    type="password"
                    label="Password"
                    component={TextField}
                    validate={[RequiredValidator]}
                    className={classes.gutterBottom}
                  />
                  <Button
                    variant="contained"
                    onClick={onSubmit}
                    disabled={submitting}
                    color="primary"
                    size="large"
                    type="submit"
                    className={classes.loginButton}
                  >
                    Login
                  </Button>
                </form>
                {/* <Typography align="right" className={classes.passwordResetLink} onClick={()=>{this.setState({showForgetPasswordDialog:true})}}>Reset Password</Typography> */}
              </div>
              :
              <div className={classes.secondAuthBox}>
                <Dialog
                  open={this.state.showResetAuthKeyDialog}
                  keepMounted
                >
                  <DialogContent>
                    <DialogContentText>
                      Please contact HVB support to accrue new one-time token before you proceed to reset authorization key on this device.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary"
                      onClick={()=>{
                        this.props.clearSecondAuth();
                        this.setState({'showResetAuthKeyDialog': false});
                      }}
                    >
                      RESET AUTH KEY
                    </Button>
                    <Button onClick={()=>{this.setState({'showResetAuthKeyDialog': false})}}>CANCEL</Button>
                  </DialogActions>
                </Dialog>
                <Typography variant="p" >Device authorization key was detected, please click the button below to validate with server. </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {this.props.validateKey(secondAuthKey)}}
                  className={classes.validateKeyButton}
                >
                  Validate
                </Button>
                <Typography variant="p" >If valiatation above failed for serval times, please contact HVB support to accrue new one-time token to authorize this device again. </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={()=>{this.setState({'showResetAuthKeyDialog': true})}}
                  className={classes.validateKeyButton}
                >
                  RESET AUTH KEY
                </Button>
              </div>
            ) : (
              <div className={classes.secondAuthBox}>
                <Typography variant="p">This device is not authorized to access ARIA. Please contact HVB support to accrue one-time token to authorize. </Typography>
                <GetSecondAuthKeyForm
                  secondAuthKey={secondAuthKey}
                  getKey={this.props.getKey}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </DocumentMeta>
    );
  }
}

const EMPTY_FORM = {
  username: null,
  password: null,
};

const LoginForm = reduxForm({
  form: 'LoginForm',
  enableReinitialize: true,
  initialValues: EMPTY_FORM,
});

export default withStyles(styles)(LoginForm(LoginView));
