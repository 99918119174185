import axios from 'app/helpers/MyAxios';
import * as Types from './types';

export default {
  fetchAnalysisTypeCode: () =>
    dispatch => axios.get(axios.addApiUrl(`code/analysis-type`))
      .then((response) => {
        dispatch({ type: Types.FETCH_ANALYSIS_TYPE_CODE, analysisTypes: response.data });
        return response.data;
      }),
};
