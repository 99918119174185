import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

export const MIN_UPLOAD_FILESIZE = 1;
export const MAX_UPLOAD_FILESIZE = 5242880; // 5MiB
export const ACCEPT_UPLOAD_FILE = [
  '.png',
  '.jpg',
  '.jpeg',
].join(',');

const styles = theme => ({
  zoneContainer: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    borderRadius: theme.spacing.unit,
  },
  error: {
    borderColor: theme.palette.error.dark,
  },
  image: {
    maxWidth: '98%',
  },
  zone: {
    flex: '1 1 auto',
    height: 'auto',
    border: 0,
    padding: theme.spacing.unit * 2,
    cursor: 'pointer',
  },
  removeButton: {
    padding: theme.spacing.unit * 2,
  },
});

const hasFile = value => (Array.isArray(value) && value.length > 0);

class DropzoneFileUpload extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({}).isRequired,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    minSize: PropTypes.number,
    maxSize: PropTypes.number,
    accept: PropTypes.string,
  };
  static defaultProps = {
    label: '',
    className: null,
    minSize: MIN_UPLOAD_FILESIZE,
    maxSize: MAX_UPLOAD_FILESIZE,
    accept: ACCEPT_UPLOAD_FILE,
    noFileMessage: 'Please click here or drag-and-drop file to here.',
  };

  constructor() {
    super();
    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.onClearFiles = this.onClearFiles.bind(this);
  }

  state = {
    imgSrc: null,
    rejected: null,
  };

  onDropAccepted(files) {
    const { input: { onChange } } = this.props;
    onChange(files);
    if (Array.isArray(files) && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({ imgSrc: reader.result, rejected: null });
      };
      reader.readAsDataURL(files[0]);
    }
  }

  onDropRejected = () => {
    this.setState({
      rejected: 'File type or size is not accepted.',
    });
  }

  onClearFiles() {
    const { input: { onChange } } = this.props;
    onChange('');
  }

  renderImage() {
    const { classes } = this.props;
    const files = this.props.input.value;
    const { imgSrc } = this.state;
    if (Array.isArray(files) && files[0] && imgSrc) {
      return <img src={imgSrc} className={classes.image} alt={files.name} />;
    }
    return <CircularProgress size={72} />;
  }

  render() {
    const {
      classes,
      minSize,
      maxSize,
      accept,
      noFileMessage,
      meta,
      input: {
        onChange, value, ...inputRest
      },
    } = this.props;
    const hasError = (meta.touched && (meta.error || meta.warning));
    return (
      <div
        className={cx(
            this.props.className,
            classes.zoneContainer, 
            {[classes.error]: hasError,}
          )}
      >
        <Dropzone
          className={classes.zone}
          minSize={minSize}
          maxSize={maxSize}
          accept={accept}
          {...inputRest}
          multiple={false}
          value={value}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
        >
          {hasFile(value) ? this.renderImage() : <Typography>{noFileMessage}</Typography>}
          {this.state.rejected ? <Typography variant="h6" color="error">{this.state.rejected}</Typography>:null}
          {hasError ?<Typography variant="h6" color="error">{meta.error}</Typography> : null}
        </Dropzone>
        <div className={classes.removeButton}>
          {hasFile(value) ? <Button onClick={this.onClearFiles}>Remove</Button> : ""}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DropzoneFileUpload);
