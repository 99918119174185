import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select'; 
import MenuItem from '@material-ui/core/MenuItem';
import IconAccountCircle from '@material-ui/icons/AccountCircle';
import IconComputer from '@material-ui/icons/Computer';
import IconLock from '@material-ui/icons/Lock';
import ChangePasswordForm from './ChangePasswordForm';

const styles = theme => ({
  toolbar: {
    marginTop: 5,
  },
  paper:{
    padding: theme.spacing.unit * 2,
  },
  card: {
    width: '98%',
    padding: theme.spacing.unit * 2,
  },
  twoColumn:{
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'no-wrap',
    padding: theme.spacing.unit * 2,
  },
  inlineHalfWidth: {
    display: 'inline',
    width: '50%',
    paddingLeft: theme.spacing.unit,
  },
});

class AccountMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



  render() {
    const { classes, selectedSite, loginUser } = this.props;
    return (
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5">My Account</Typography>
        </Toolbar>
        <Grid container spacing={24}>
          <Grid container item xs={12} md={6}>
           <Card className={classes.card}>
              <CardHeader 
                title="Account Information" 
                avatar={<IconAccountCircle/>}
              />
              <CardContent className={classes.cardContent} >
                <div className={classes.twoColumn}>
                  <Typography component="span" className={classes.inlineHalfWidth}>
                    Name
                  </Typography>
                  <Typography component="span" className={classes.inlineHalfWidth}>
                    {loginUser.fullname}
                  </Typography> 
                </div>
                <Divider/>
                <div className={classes.twoColumn}>
                  <Typography component="span" className={classes.inlineHalfWidth}>
                    Email
                  </Typography>
                  <Typography component="span" className={classes.inlineHalfWidth}>
                  {loginUser.email}
                  </Typography> 
                </div>
                <Divider/>
              </CardContent>
            </Card>          
          </Grid>
          <Grid container item xs={12} md={6}>
           <Card className={classes.card}>
              <CardHeader 
                title="Site Infomation" 
                avatar={<IconComputer/>}
              />
              <CardContent className={classes.cardContent}>
                <div className={classes.twoColumn}>
                  <Typography component="span" className={classes.inlineHalfWidth}>
                    Company / Organization
                  </Typography>
                  <Typography component="span" className={classes.inlineHalfWidth}>
                  {loginUser.partner.name}
                  </Typography> 
                </div>
                <Divider/>
                <div className={classes.twoColumn}>                  
                  <Typography component="span" className={classes.inlineHalfWidth}>
                    Current Site
                  </Typography>
                  <Select
                    className={classes.inlineHalfWidth}
                    value = {selectedSite ? selectedSite.id : "0"}
                    onChange = {(event)=>{if (event.target.value !== "0" ) this.props.selectSite(event.target.value); else this.props.unselectSite();}}
                    inputProps={{
                      id: 'current-site-select',
                    }}
                  >
                    <MenuItem key="0" value="0">
                      Not Selected
                    </MenuItem>
                    {loginUser.sites.map(site => (
                      <MenuItem key={site.id} value={site.id}>
                        {site.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <Divider/>                
              </CardContent>
            </Card>   
          </Grid>
          <Grid container item xs={12} md={6}>
           <Card className={classes.card}>
              <CardHeader 
                title="Change Password" 
                avatar={<IconLock/>}
              />
              <CardContent className={classes.cardContent} >
                <ChangePasswordForm/>              
              </CardContent>
            </Card>          
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
export default (withStyles(styles)(AccountMe));
