import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import persistState, { mergePersistedState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import debounce from 'redux-localstorage-debounce';
import rootReducer from './reducers';

const middlewares = [thunk];

const presistedReducer = compose(mergePersistedState())(rootReducer);

const storage = compose(
  debounce(300),
)(adapter(window.localStorage)); // eslint-disable-line

const createStoreWithMiddleware = compose(
  applyMiddleware(...middlewares),
  persistState(storage, 'aria-portal-web'),
)(createStore);

const store = createStoreWithMiddleware(presistedReducer);

export default store;
