import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import account from './account/reducer';
import code from './code/reducer';
import leftDrawer from './leftDrawer/reducer';
import notificationSnackbar from './notificationSnackbar/reducer';
import record from './record/reducer';
import secondAuth from './secondAuth/reducer';
import selected from './selected/reducer';
import tasks from './task/reducer';

export default combineReducers({
  form,
  account,
  code,
  leftDrawer,
  notificationSnackbar,
  record,
  secondAuth,
  selected,
  tasks,
});
