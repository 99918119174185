import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TopAppBar from './TopAppBar';
import LeftDrawer from './LeftDrawer';
import LeftDrawerActions from 'app/redux/leftDrawer/actions';

const styles = theme => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    height: '100vh',
    width: '100%',

  },
  content: {
    marginLeft: 0,
    marginTop: 70,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingTop: 0,
    paddingLeft: 30,
    paddingRight: 30,
  },
  copyright: {
    padding: theme.spacing.unit,
    textAlign: 'left',
  },
  flex1: {
    flex: 1,
  },
});

const useLogedInLayout = (Component) => {
  const LogedInLayout = ({ classes, drawerOpen, ...props }) => (
    <div className={classes.appFrame}>
      <CssBaseline />
      <TopAppBar />
      <LeftDrawer />
      <main className={cx(classes.content)} >
        <div className={classes.flex1}>
          <Component {...props} />
        </div>
        <div className={classes.copyright}>
          <Typography variant="caption">Health View Bioanalytic Limited © {new Date().getFullYear()}. All rights reserved.</Typography>
        </div>
      </main>
    </div >
  );

  LogedInLayout.propTypes = {
    classes: PropTypes.shape({}).isRequired,
  };

  const mapStateToProps = ({ leftDrawer }) => leftDrawer;
  const mapDispatchToProps = {
    ...LeftDrawerActions,
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(LogedInLayout),
  );
};

export default useLogedInLayout;
