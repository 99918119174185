import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import Config from 'app/constants/Config';
import store from 'app/redux/store';
import MainApp from 'app/MainApp';

const MUItheme = createMuiTheme({
  palette: {
    primary: {
      light: '#00A09D',
      main: '#00A09D',
      dark: '#00A09D',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#c7f76d',
      main: '#94c43b',
      dark: '#629300',
      contrastText: '#ffffff',
    },
    tertiary: {
      light: '#b2d35e',
      main: '#80a22e',
      dark: '#507300',
      contrastText: '#000000',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Barlow", "Helvetica", "Arial", sans-serif',
    fontWeightMedium: 700,
    headline: {
      lineHeight: '1.2em',
    },
  },
});

const App = () => (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={MUItheme}>
          <CssBaseline />
          <BrowserRouter basename={Config.basename}>
            <MainApp />
          </BrowserRouter>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
);

export default App;
