import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CustomToolbar from 'app/components/CustomToolbar';
import axios from 'app/helpers/MyAxios';

import { Field, reduxForm } from 'redux-form';
import { handle422 } from 'app/helpers/ReduxFormHelper';
import SearchFormBase from 'app/components/SearchFormBase';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  paper:{
    padding: theme.spacing.unit * 2,
  },
  UsageFormField: {
    marginRight: theme.spacing.unit * 2,
  },
  selectDropDownBox: {
    marginRight: theme.spacing.unit * 3,
  },
  heightAlign: {
    height: 34,
  },
  resultTableContainer: {
    marginTop: 20,
  },
  selectDateMsg: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 3,
  },
  analystType: {
    paddingLeft: theme.spacing.unit * 3,
  },
});
//
const EMPTY_FORM = {
  results:[],
};
var initDate = new Date();
var currentYear = initDate.getFullYear();
var currentMonth = initDate.getMonth()+1;
if (currentMonth < 10) { currentMonth = '0' + currentMonth; }
const columnData = [
  { id: 'anaylsisTypeLabel', label: 'Analysis Type',width: 300 },
  { id: 'chargeCaseLabel', label: 'Charge Case',width: 200 },
  { id: 'waivedCaseLabel', label: 'Waived Case',width: 200 },
  { id: 'testingCaseLabel', label: 'Testing Case',width: 200 },
];
class UsageReportList extends Component {
  static propTypes = {
    init: PropTypes.bool,
  }
  static defaultProps = {
    data: [],
    init: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      results: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }
  fetchUsage(start,end) {
    this.setState(
      async () => {
        try{
          const response = await axios.get(axios.addApiUrl(`site-usage?start=${start}&end=${end}`));
          // console.log('response',response);
          this.setState({ results: response.data.sites})
        } catch (e){
          handle422(e.response);
          throw e;
        }
      },
    );
  }

  onSubmit(values) {
    // console.log('submitForm',values);
    var formValue = values.year+'-'+values.month;
    var dateEndofMonth = moment(formValue, "YYYY-MM").daysInMonth();
    var startDate = formValue+'-01';
    var endDate = formValue+'-'+dateEndofMonth;
    if(startDate!=null && endDate !=null){
      this.fetchUsage(startDate,endDate);
    }
  }

  clearForm(event = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.setState({
      ...EMPTY_FORM,
    }, () => this.onSubmit(EMPTY_FORM));
    this.props.reset();
  }

  renderSiteAnalystType(types){
    const {classes} = this.props;
    const typeList = Object.entries(types).map(([key,value]) => {
      return(
        <TableRow key={key}>
          <TableCell colSpan={1} key={key}><Typography className={classes.analystType}>{key}</Typography></TableCell>
          <TableCell colSpan={1}><Typography className={classes.analystType}>{value.chargeCase ? value.chargeCase.count.toString() : 0}</Typography></TableCell>
          <TableCell colSpan={1}><Typography className={classes.analystType}>{value.waivedCase ? value.waivedCase.count.toString() : 0}</Typography></TableCell>
          <TableCell colSpan={1}><Typography className={classes.analystType}>{value.testingCase ? value.testingCase.count.toString() : 0}</Typography></TableCell>
        </TableRow>
      );
    })
    return(
      Object.keys(types).length !==0 ?
        typeList
      :
        <TableRow><TableCell colSpan={6}>No Records.</TableCell></TableRow>
    )
  }

  renderSite(result) {
    const {classes} = this.props;
    // console.log('renderSite', result);
    return(
        <Table key={result.siteID} className={classes.resultTableContainer}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}><Typography variant="subtitle1">{result.siteName}</Typography></TableCell>
            </TableRow>
            { Object.keys(result.siteAnalystType).length !==0 ?
              <TableRow>
                {columnData.map(column => (
                  <TableCell
                    key={column.id}
                    //colSpan={column.colSpan}
                    width={column.width}
                  >
                    {column.label}
                  </TableCell>
                ), this)}
              </TableRow>
              : null
            }
          </TableHead>
          <TableBody>
            {this.renderSiteAnalystType(result.siteAnalystType)}
          </TableBody>
        </Table>
    )
  }

  render() {
    const { classes, handleSubmit } = this.props;
    const { results } = this.state;
    // console.log('results',results);
    const renderFromHelper = ({ touched, error }) => {
      if (!(touched && error)) {
        return
      } else {
        return <FormHelperText>{touched && error}</FormHelperText>
      }
    }

    const renderSelectField = ({
      input,
      label,
      name,
      required,
      meta: { touched, error },
      children,
      ...custom
    }) => (
      <FormControl error={touched && error} required>
        <InputLabel htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          native
          {...input}
          {...custom}
          inputProps={{
            name: {name},
            id: {name},
            className: classes.selectDropDownBox,
            classes:{
              root: classes.heightAlign,
            }
          }}
        >
          {children}
        </Select>
        {renderFromHelper({ touched, error })}
      </FormControl>
    )

    var yearList = [];

    for (var i=2019; i<=currentYear;i++ ){
      yearList = yearList.concat(i);
    }

    return (
      <Paper className={classes.paper}>
        <CustomToolbar
          title="Usage Reports"
        />
        <SearchFormBase onSubmit={handleSubmit(values => this.onSubmit(values))} onClear={this.clearForm}>
          <Field
            className={classes.UsageFormField}
            component={renderSelectField}
            label="Year"
            name='year'
            required
          >
          {yearList.map(y=>(
            <option value={y}>{y}</option>
          ))}
          </Field>
          <Field
            className={classes.UsageFormField}
            component={renderSelectField}
            label="Month"
            name='month'
            required
          >
            <option value="01">Jan</option>
            <option value="02">Feb</option>
            <option value="03">Mar</option>
            <option value="04">Apr</option>
            <option value="05">May</option>
            <option value="06">Jun</option>
            <option value="07">Jul</option>
            <option value="08">Aug</option>
            <option value="09">Sep</option>
            <option value="10">Oct</option>
            <option value="11">Nov</option>
            <option value="12">Dec</option>
          </Field>
        </SearchFormBase>
        {
          results.length === 0 ?
            ''
          :
            results.map(r => this.renderSite(r))
        }
      </Paper>
    );
  }
}

export default (withStyles(styles)(reduxForm({
  form: 'UsageReportForm',
  initialValues: {
    year: currentYear,
    month: currentMonth,
  },
})(UsageReportList)));
