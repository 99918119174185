import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RecordActions from 'app/redux/record/actions';
import NotificationSnackbarActions from 'app/redux/notificationSnackbar/actions';
import RecordCreate from './RecordCreate';

const mapStateToProps = ({selected}) => ({
  selectedSite: selected.site,
  selectedAnalysisTypeCodes : [],
  initialValues: {
    site_id: selected.site.id,
    camera_id: selected.site.cameras[0].id,
    analysis_type_codes: [],
  },
});

const mapDispatchToProps = {
  create: RecordActions.create,
  setNotiMessage: NotificationSnackbarActions.setNotiMessage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordCreate));
