import * as Types from './types';
import * as AccountTypes from 'app/redux/account/types';

const INITIAL_STATE = {
  notiMessage: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_NOTI_MESSAGE:
      return {
        ...state,
        notiMessage: action.notiMessage,
      };
    case AccountTypes.LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
