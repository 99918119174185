import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

class EnhancedTableHead extends Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columnData: PropTypes.array.isRequired, // eslint-disable-line
    noCheckbox: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    noCheckbox: false,
    children: null,
    onSelectAllClick: null,
  }

  createSortHandler = property => event =>
    this.props.onRequestSort(event, property);


  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, columnData, noCheckbox, children,
    } = this.props;
    return (
      <TableHead>
        <TableRow>
          {!noCheckbox ?
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
            : null
          }
          {columnData.map(column => (
            <TableCell
              key={column.id}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : (column.padding || 'default')}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.sortable ?
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip> : column.label
              }
            </TableCell>
          ), this)}
        </TableRow>
        {children}
      </TableHead >
    );
  }
}

export default EnhancedTableHead;
