import { connect } from 'react-redux';
import SelectedActions from 'app/redux/selected/actions';

import AccountMe from './AccountMe';

const mapStateToProps = ({ selected, account }) => ({
  loginUser: account.user,
  selectedSite: selected.site,
});

const mapDispatchToProps = {
  selectSite: SelectedActions.selectSite,
  unselectSite: SelectedActions.unselectSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMe);
