import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextField } from 'redux-form-material-ui';
import { withStyles } from '@material-ui/core/styles';

import Config from 'app/constants/Config';

const styles = theme => ({
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
});

const MyCaptcha = props => (
  <ReCAPTCHA
    sitekey={props.sitekey}
    onChange={props.onChange}
    size={props.size}
    badge={props.badge}
    theme={props.theme}
  />
);

const ReCaptcha = ({ classes, InputProps, input, ...props }) => (
  <TextField
    {...props}
    InputProps={{
      disableUnderline: true,
      ...InputProps,
      classes,
      inputProps: {
        sitekey: Config.RECAPTCHA_SITE_KEY,
        onChange: input.onChange,
      },
      inputComponent: MyCaptcha,
    }}
  />
);

export default withStyles(styles)(ReCaptcha);
