import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from 'app/redux/task/actions';
import accountActions from 'app/redux/account/actions';
import ManualActions from 'app/redux/manual/actions';
import DashboardMain from './DashboardMain';

const mapStateToProps = ({ selected, account, tasks }) => ({
    loginUser: account.user,
    selectedSite: selected.site,
    usertasks: account.user.tasks,
    tasklist: tasks,
});

const mapDispatchToProps = {
    fetchOne: actions.fetchOne,
    fetchManual: ManualActions.fetchManual,
    ...accountActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardMain));
