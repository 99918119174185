import axios from 'app/helpers/MyAxios';
import * as Types from './types';

export default {
  login: loginForm =>
    dispatch => axios.post(axios.addApiUrl('account/login'), loginForm)
      .then((response) => {
        dispatch({ type: Types.LOGIN_USER, user: response.data });
        return response.data;
      }),
  logout: () =>
    async dispatch => {
      dispatch({ type: Types.LOGOUT_USER });
    },
  dotask: () =>
    async dispatch => axios.get(axios.addApiUrl('account/me'))
      .then(response =>{
          dispatch({ type: Types.USER_DOTASK, user: response.data });
      }),
};
