import _ from 'lodash';
import querystring from 'query-string';

const MyQuery = (search, sendEmpty = false) => {
  let data = {
    ...search.form,
    page: search.page ? search.page + 1 : null,
    'per-page': search.pageSize ? search.pageSize : '0',
  }; // we stored page zero-based.
  data.sort = '';
  for (var i = 0; i < search.sort.length; i++) {
      data.sort += (search.sort[i].asc ? '' : '-') + search.sort[i].key + ',';
  }
  // remove empty array
  data = _.pickBy(
    data,
    // filter empty array as querystring have strange behaviour
    value => (Array.isArray(value) ? Boolean(value.length) : true),
  );
  if (!sendEmpty) {
    // remove empty input to shorten the params
    data = _.pickBy(
      data,
      // filter empty array as querystring have strange behaviour
      value => Boolean(value),
    );
  }
  return querystring.stringify(data, {arrayFormat: 'index'});
};

export default MyQuery;
