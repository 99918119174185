import axios from 'axios';
import { LOGOUT_USER } from 'app/redux/account/types';
import Config from 'app/constants/Config';
import store from 'app/redux/store';

const defaultRequestConfig = {
};

const handleException = (e) => {
  if (e.response && e.response.status === 403) {
    store.dispatch({ type: LOGOUT_USER });
  }
  if (e.response && e.response.status === 401) {
    store.dispatch({ type: LOGOUT_USER });
  }
};

const getAuthorization = () => {
  const { account } = store.getState();
  if (account.user && account.user.auth_key) {
    return {
      Authorization: `Bearer ${account.user.auth_key}`,
    };
  }
  return null;
};

const getSecondAuthKey = () => {
  const { secondAuth } = store.getState();
  if (secondAuth.secondAuthKey) {
    return {
      secondAuthKey: `${secondAuth.secondAuthKey}`,
    };
  }
  return null;
};

const buildConfig = (config = {}) => {
  const { headers, ...rest } = config;
  return {
    ...defaultRequestConfig,
    ...rest,
    headers: {
      ...getAuthorization(),
      ...getSecondAuthKey(),
      ...headers,
    },
  };
};


export default {
  addApiUrl: relativePath => (`${Config.apiHost}${relativePath}`),
  get: async (url, config = {}) => {
    try {
      const response = await axios.get(url, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  post: async (url, data, config = {}) => {
    try {
      // console.log(data, buildConfig(config));
      const response = await axios.post(url, data, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  patch: async (url, data, config = {}) => {
    try {
      const response = await axios.patch(url, data, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  put: async (url, data, config = {}) => {
    try {
      return await axios.put(url, data, buildConfig(config));
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  delete: async (url, config = {}) => {
    try {
      const response = await axios.delete(url, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
};
