import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import IconMoreHoriz from '@material-ui/icons/MoreHoriz';
import IconMoreVert from '@material-ui/icons/MoreVert';
import Spacer from 'app/components/Spacer';

const styles = theme => ({
  toolbar: {
    marginBottom: theme.spacing.unit * 2,
  },
  fab: {
    marginLeft: theme.spacing.unit * 1.5,
    marginTop: theme.spacing.unit * 1,
  },
  collapseMenu: {
    position: 'absolute',
    top: theme.spacing.unit * 8,
    right: theme.spacing.unit * 4,
    width: 50,
  },
});

class CustomToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }
  render(){
    const { classes, title, rightButtons, moreButtons } = this.props;
    return(
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5">{title}</Typography>
          <Spacer />
          <div>
            {
              rightButtons ?
                rightButtons.map(button => (
                  <span key={button.title}>
                    <Tooltip title={button.title} >
                      <Fab size="medium" color="primary" aria-label={button.title} className={classes.fab} onClick={button.onClick} disabled={button.disabled}>
                        {button.icon}
                      </Fab>
                    </Tooltip>
                  </span>
                ))
              :
                ''
            }
            {
              moreButtons ?
                <span>
                  <Tooltip title={this.state.menuOpen ? "Less" : "More"}>
                    <Fab size="medium" color="primary" aria-label={this.state.menuOpen ? "Less" : "More"} className={classes.fab} onClick={ () => (this.setState({menuOpen:!this.state.menuOpen}))}>
                      { this.state.menuOpen ? <IconMoreHoriz /> : <IconMoreVert /> }
                    </Fab>
                  </Tooltip>
                  <Collapse
                    component="div"
                    className={classes.collapseMenu}
                    in={this.state.menuOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    {
                      moreButtons.map(button => (
                        <span key={button.title}>
                          <Tooltip title={button.title}>
                            <Fab size="medium" color="primary" aria-label="Filter" className={classes.fab} onClick={button.onClick}>
                              {button.icon}
                            </Fab>
                          </Tooltip>
                        </span>
                      ))
                    }
                  </Collapse>
                </span>
              :
                ''
            }
          </div>
        </Toolbar>
        <Divider/>
      </div>
    );
  }
}
export default (withStyles(styles)(CustomToolbar));
