import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconRefresh from '@material-ui/icons/Refresh';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
import { EnhancedTable } from 'app/components/table';
import CustomToolbar from 'app/components/CustomToolbar';
import RecordSearchForm from './RecordSearchForm';
import axios from 'app/helpers/MyAxios';

const styles = theme => ({
  paper:{
    padding: theme.spacing.unit * 2,
  },
  buttonIcon:{
    // paddingRight: theme.spacing.unit * 1,
  },
  analysisType:{
    minWidth: 100,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  analysisStatus:{
    minWidth: 100,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  analysisActions:{
    minWidth: 60,
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
  },
});

const columnData = [
  { id: 'ref_no', label: 'ARIA Ref No', sortable: true },
  { id: 'customer_ref_no', label: 'Customer Ref No', sortable: true },
  { id: 'created_at', padding: 'checkbox', label: 'Created At', sortable: true },
  { id: 'anaylsis',  padding: 'checkbox', label: 'Analysis', sortable: false },
  { id: 'status',  padding: 'checkbox', label: 'Status', sortable: false },
  { id: 'actions',  padding: 'checkbox', label: 'Actions', sortable: false },
];

class RecordList extends Component {
  static defaultProps = {
    page: 0,
    pageSize: 20,
    totalElements: 0,
    sort: [{
      key: 'id',
      asc: false,
    }],
    data: [],
    init: false,
  }
  constructor(props, context) {
    super(props, context);
    this.state = {
      updateSubmitting: false,
    };
  }

  componentDidMount() {
    const { searchFormSubmit, fetchAnalysisTypeCode, selectedSite } = this.props;
    fetchAnalysisTypeCode().then(() => {
      searchFormSubmit({site_id: selectedSite.id})
        .catch(err => console.log('error', err));
    });
  }
  handleChangePage(page) {
    this.props.pageChange(page);
  }
  handleChangeSort(property) {
    const { sort } = this.props;
    const newSort = [{
      key: property,
      asc: sort[0].key === property ? !sort[0].asc : true,
    }];
    this.props.sortChange(newSort);
  }
  handleViewReportClick(id){
    try {
      axios.get(axios.addApiUrl(`pdf/aria-report?analysis_id=${id}`), {
        responseType: 'arraybuffer',
      }).then((response) => {
        var blob = new Blob([response.data] , {
            type: 'application/pdf'
        });
        const url = window.URL.createObjectURL(blob);
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
          var reader = new FileReader();
          reader.onload = function(e){
            window.open(reader.result, '_blank');
          }
          reader.readAsDataURL(blob);
        } else {
          window.open(url, '_blank');
        }
      })
    } catch (e) {
      console.log(e);
    }
  }
  handleRefreshStatusClick(id){
    const { searchFormSubmit, setNotiMessage } = this.props;
    this.setState({updateSubmitting: true});
    axios.get(axios.addApiUrl(`cloud-analyser/refresh?analysis_id=${id}`))
    .then((response) => {  
      searchFormSubmit({site_id: this.props.selectedSite.id});
      setNotiMessage("Analysis status updated.");
      this.setState({updateSubmitting: false});
    })
    .catch((error) => {
      setNotiMessage("Failed to update analysis status");
      this.setState({updateSubmitting: false});
    })
  }
  handleRefreshAllClick(){
    const { searchFormSubmit, setNotiMessage } = this.props;
    this.setState({updateSubmitting: true});
    axios.get(axios.addApiUrl(`cloud-analyser/refresh`))
    .then((response) => {  
      searchFormSubmit({site_id: this.props.selectedSite.id});
      setNotiMessage("Analysis status updated.");
      this.setState({updateSubmitting: false});
    })
    .catch((error) => {
      setNotiMessage("Failed to update analysis status");
      this.setState({updateSubmitting: false});
    })
  }

  renderRow = (row) => {
    const {classes, analysisTypes} = this.props;
    return (
      <TableRow
        key={row.id}
        hover
        tabIndex={-1}
      >
        <TableCell>
          {row.ref_no}
        </TableCell>
        <TableCell>
          {row.customer_ref_no}
        </TableCell> 
        <TableCell padding="checkbox">
          {row.created_at}
        </TableCell>
        <TableCell padding="checkbox">
          { row.analysis.map(ana => (
                <div key={ana.id} className={classes.analysisType}>
                  {analysisTypes.find((anaType) => {return anaType.code === ana.type_code}).name}
                </div>
              )
            )
          }
        </TableCell>
        <TableCell padding="checkbox">
          { row.analysis.map( ana => (
                <div key={ana.id} className={classes.analysisStatus}>
                  {ana.status_code}
                </div>
              )
            )
          }
        </TableCell>
        <TableCell padding="checkbox">
          { row.analysis.map( ana => (
                <div key={ana.id} className={classes.analysisActions}>
                  { ana.status_code === "COMPLETED" ? 
                    (
                      <Tooltip title="View Report">
                        <Button variant="contained" onClick = {()=>{this.handleViewReportClick(ana.id);}} size="small" aria-label="View Report">
                          <IconLibraryBooks className={classes.buttonIcon} /> 
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Update Status">
                        <Button variant="contained" onClick = {()=>{this.handleRefreshStatusClick(ana.id);}} disabled={this.state.updateSubmitting} size="small" aria-label="Refresh Status">
                          <IconRefresh className={classes.buttonIcon} /> 
                        </Button>
                      </Tooltip>
                    )
                  }
                </div>
              )
            )
          }
        </TableCell>
      </TableRow>);
  }
  render() {
    const { page, pageSize, data, totalElements, sort, history, classes, searchFormSubmit, selectedSite } = this.props;
    return (
      <Paper className={classes.paper}>
        <CustomToolbar
          title="Retinal Image Analysis"
          rightButtons = {[
            {title: "New Analysis", icon:<IconAdd/>, onClick:()=>{history.push('/records/create')}},
            {title: "Refresh All Status", icon:<IconRefresh/>, onClick:()=>{this.handleRefreshAllClick()}, disabled:this.state.updateSubmitting},           
          ]}
        />
        <RecordSearchForm
          searchFormSubmit = {searchFormSubmit}
          searchRefNo = {this.props.form.ref_no}
          searchCustomerRefNo = {this.props.form.customer_ref_no}
          selectedSiteId = {selectedSite.id}
        />
        <EnhancedTable
          totalElements={totalElements}
          pageSize={pageSize}
          page={page}
          sort={sort}
          columnData={columnData}
          data={data}
          onChangePage={(event, nextPage) => this.handleChangePage(nextPage)}
          onChangeSort={(event, property) => this.handleChangeSort(property)}
          noCheckbox = {true}
          renderRow={r => this.renderRow(r)}
        />
      </Paper>
    );
  }
}
export default (withStyles(styles)(RecordList));
