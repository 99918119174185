import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconPerson from '@material-ui/icons/Person';

import Dialog from '@material-ui/core/Dialog';
import UserManualForm from './UserManualFormContainer';

import axios from 'app/helpers/MyAxios';
import { handle422 } from 'app/helpers/ReduxFormHelper';


const styles = theme => ({
  paper:{
    padding: theme.spacing.unit * 2,
  },
  card: {
    width: '98%',
    padding: theme.spacing.unit * 2,
  },
  cardContent: {
    minHeight: 250,
  },
  userAvatar:{
    fontSize: 40,
    width: 100,
    height: 100,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
  },
  UserManualPopup: {
    minHeight: '80vh',
  },
  link:{
    color: theme.palette.primary.main,
  },
});


class DashboardMain extends Component {

  state = {
    openManual: false, //TODO: if first login true
    //pageNumber: 1,
  };


  componentDidMount() {
    const { fetchManual, dotask } = this.props;
    this.checkTask();
    dotask();
    fetchManual();
  }

  checkTask() {
    this.setState(
      async () => {
        try{
          const activeUserGuide = await this.props.fetchOne('userguide');
          if (activeUserGuide){
            const taskID =  activeUserGuide[0] && activeUserGuide[0].id;
            const response = await axios.get(axios.addApiUrl(`task/takes?id=${taskID}`));
            if(!response.data){ //check if task is not null
              this.setState({
                openManual: true,
              })
            }
          }
        } catch (e){
          handle422(e.response);
          throw e;
        }
      },
    );
  }

  checkReadUserManual = tasks => (tasks!==null && tasks!==undefined) && tasks.filter(task => task.type_code==="userguide").length > 0;

  handleUserMaualOpen =() => {
    this.setState({
      openManual: true,
    });
  };

  handleManualClose = () =>{
    this.setState({
      openManual: false,
    });
  };

  handleViewManualClick(){
    try {
      axios.get(axios.addApiUrl(`manual/manual`), {
        responseType: 'arraybuffer',
      }).then((response) => {
        var blob = new Blob([response.data] , {
            type: 'application/pdf'
        });
        const url = window.URL.createObjectURL(blob);
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
          var reader = new FileReader();
          reader.onload = function(e){
            window.open(reader.result, '_blank');
          }
          reader.readAsDataURL(blob);
        } else {
          window.open(url, '_blank');
        }
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { classes, loginUser, usertasks, tasklist } = this.props;
    return (
      <Paper className={classes.paper}>
        < Grid container spacing={24}>
          <Grid container item xs={12} justify="center" alignItems="center">
            <Avatar className={classes.userAvatar}><IconPerson style={{ fontSize: 70 }} /></Avatar>
          </Grid>
          <Grid container item xs={12} justify="center" alignItems="center">
            <Typography variant="h6">Welcome, {loginUser.fullname}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid container item xs={12} md={6}>
          <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5">
                  Automatic Retinal Image Analysis
                </Typography>
                <Typography component="p">
                  Automatic Retinal Image Analysis (ARIA) is a digital health cloud-based platform for screening of risk of various diseases.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" component={Link} to={`/records/create`} aria-label="Start Analysis Now">
                  START ANALYSIS NOW
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5">
                  ARIA-eWMH
                </Typography>
                <Typography component="p">
                Automatic Retinal Image Analysis for estimated White Matter Hyperintensities (ARIA-eWMH) was developed to assess the risk of severe age-related white matter hyperintensities (ARWMH) in the brain based on automatic retinal images analysis (ARIA), using cerebral magnetic resonance imaging (MRI) as gold standard and achieved more than 90% sensitivity and specificity.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick = {()=>{window.open("https://www.healthviewbio.com/products/automatic-retinal-image-analysis-for-estimated-white-matter-hyperintensities-aria-ewmh/")}}>
                LEARN MORE
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Dialog
            open={this.state.openManual}
            onClose={this.handleManualClose}
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            className={classes.UserManualPopup}
          >
            <UserManualForm
              usermanual = {() => this.handleViewManualClick()}
              taskContent={tasklist.data[0]}
              onClose={() => this.setState({ openManual: false })}
            />
          </Dialog>
          {this.checkReadUserManual(usertasks) ?
          <Grid container item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5">
                  User Manual
                </Typography>
                  <ul><li>
                    <a  className={classes.link} href="#" onClick = {()=>{this.handleViewManualClick();}} >
                      <Typography color="primary">Automatic Retinal Image Analysis (ARIA) General System Operation Manual</Typography>
                    </a>
                  </li></ul>
              </CardContent>
              <CardActions>

              </CardActions>
            </Card>
          </Grid>
          :
            null
          }
        </Grid>
      </Paper>
    );
  }
}
export default (withStyles(styles)(DashboardMain));
